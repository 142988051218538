import React from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.css';
import ScrambleText from '../ScrambleText/ScrambleText';
import useSectionInView from '../../helpers/useSectionInView';
import useParallaxOffset from '../../helpers/useParallaxOffset';
import { useState, useRef } from 'react';

const Header = ({title, subtitle, backgroundExt}) => {

  const [scrambleActive, setScrambleActive] = useState(false);
  const [sectionClass, setSectionClass] = useState("HeaderOut");

  let enterSection = false;
  let exitSection = false;

  const name = title.replaceAll(" ", "-").toLowerCase();
  const backgroundStyle = {backgroundImage: "url(./background-" + name + backgroundExt + ")"};

  useParallaxOffset(name + "-id", 0.02);

  useSectionInView(-200, (newSectionName) => {
    enterSection = !enterSection && newSectionName == title;
    exitSection = enterSection && newSectionName != title;
    
    setScrambleActive(enterSection || exitSection);

    setSectionClass(newSectionName == title ? "HeaderIn" : "HeaderOut");
  });

  return (
    <div className={styles.Header}>
      <div className={styles.Background} id={name + "-id"} style={backgroundStyle}></div>
      <div className={`container ${styles[sectionClass]}`}>
    
        <div className={styles.MainHeadingWrapper}>
          <div className="main-heading">
            <h1><ScrambleText displayText={title} active={scrambleActive} duration="0.5"></ScrambleText></h1>
          </div>
          <div className="VSpacer5px"></div>
        </div>
        <div className={styles.BreadCrumb}>{subtitle ? "// " + subtitle : ""}</div>
      </div>
    </div>
  )
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
